<template lang="pug">
app-layout.notifications
  v-app.layout-full
    page-header.sh-xsmall(title="Уведомления")
    v-main.page-notifications(app)
      v-overlay.text-center(
        :value="loading"
        color="#fff"
        absolute
        opacity="1"
      )
        ui-loader(text="Загрузка...")

      .notifications__list(v-if="notifications.length")
        notification-list-item(
          v-for="notification in paginatedNotifications"
          :key="notification.id"
          :notification="notification"
          @read="toggleRead"
        )
        ui-loader(:loading="loading")
        .px-4
          ui-btn.notifications__button(
            v-if="currentPage < getPageCount"
            third
            width="100%"
            @click="nextPage"
            :disabled="currentPage >= getPageCount"
          ) Посмотреть еще
      .notifications__list--empty(v-else)
        titled-image(small title="Пока у вас нет уведомлений")
          template(#image)
            img(src="~@/assets/96/notifications.svg")
          | Здесь будут уведомления по вашей активности на портале
    page-footer
      ui-btn(secondary width="100%" @click="readAll") Отметить все как прочитанные
</template>

<script>
  import AppLayout from '@/layouts/AppLayout';
  import PageHeader from '@/components/app/PageHeader.vue';
  import PageFooter from '@/components/app/PageFooter.vue';
  import NotificationListItem from '@/components/notifications/NotificationListItem.vue';
  import TitledImage from '@/components/TitledImage.vue';
  import { service as API } from '@/services/notifications';

  import { UiLoader, UiBtn, UiIcon } from '@/uikit';

  import { handleError } from '@/libs/handle-error';
  import { createLogger } from '@/uikit/util/logger';
  const logger = createLogger('PageNotifications', 'orange');

  export default {
    name: 'page-notifications',
    components: {
      AppLayout,
      UiLoader,
      UiBtn,
      UiIcon,
      PageHeader,
      PageFooter,
      NotificationListItem,
      TitledImage,
    },

    data() {
      return {
        notifications: [],
        loading: false,
        errorMessage: false,
        loadingText: '',
        errorText: '',
        unreadCount: this.count,
        currentPage: 1,
        perPage: 5,
      };
    },

    computed: {
      getPageCount() {
        return Math.ceil(this.notifications.length / this.perPage);
      },

      paginatedNotifications() {
        return this.notifications.slice(0, this.currentPage * this.perPage);
      },
    },

    mounted() {
      this.fetchNotifications();
    },

    methods: {
      async fetchNotifications() {
        try {
          this.loading = true;
          const { count, notifications } = await API.getNotifications();
          this.notifications = notifications;
          this.unreadCount = count;
        } catch (error) {
          handleError(error, logger);
        } finally {
          this.loading = false;
        }
      },

      toggleRead(notificationId, isRead) {
        const notificationIndex = this.notifications.findIndex(
          (notification) => notification.id === notificationId
        );
        this.notifications[notificationIndex].read = isRead;

        if (isRead) {
          this.unreadCount > 0 ? this.unreadCount-- : 0;
        } else {
          this.unreadCount++;
        }
      },

      async readAll() {
        try {
          const success = await API.markAllRead();
          if (success) {
            this.notifications.forEach((item) => (item.read = true));
            this.unreadCount = 0;
          }
        } catch (error) {
          handleError(error, logger);
        }
      },

      nextPage() {
        this.currentPage++;
      },
    },
  };
</script>

<style lang="scss">
  .page-notifications {
    height: 100%;
    min-height: 480px;

    .notifications__list {
      width: 100%;
      line-height: 18px;
    }

    .notifications__list--empty {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      padding: 32px;
    }

    .notifications__button {
      margin-top: 16px;
      margin-bottom: 24px;
    }
  }
</style>
