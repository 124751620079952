<template lang="pug">
.notification-item(:class="classes")
  .notification-item__icon
    ui-icon(name="bell")
  .notification-item__body
    .notification-item__title {{ notification.title }}
    .notification-item__content(v-html="notification.body" @click="handleLinkClick()")
    .notification-item__date {{ notification.created_at | datetime }}
  .notification-item__button
    ui-icon(
      size=18
      v-tooltip="{ content: notification.read ? 'Отметить как непрочитанное' : 'Отметить как прочитанное' }"
      :name="notification.read ? 'check-round-full' : 'check-round-empty'"
      @click="toggleReadState"
    )
</template>

<script>
  import { service as API } from '@/services/notifications';
  import { UiBtn, UiIcon } from '@/uikit/';
  import { createLogger } from '@/uikit/util';

  const logger = createLogger('NotificationListItem', 'orange');

  export default {
    name: 'notification-list-item',
    components: { UiBtn, UiIcon },
    inject: ['openDesktop'],

    props: {
      notification: { type: Object, required: true },
    },

    computed: {
      classes() {
        return [
          'notification-item',
          { 'notification-item--new': !this.notification.read },
          { 'notification-item--read': this.notification.read },
        ];
      },
    },

    methods: {
      // Переключает статус "прочитано" на противоположный
      async toggleReadState() {
        try {
          const success = await API.toggleRead(this.notification.id);
          if (success) this.$emit('read', this.notification.id, !this.notification.read);
        } catch (error) {
          logger.error(error);
        }
      },

      // Обрабатывает нажатие на ссылку в теле уведомления
      handleLinkClick() {
        const link = this.findLink();
        if (!link) {
          return;
        }

        if (this.needAuth(link.path)) {
          this.goDesktopWithAuth(link);
        } else {
          this.followLink(link.href);
        }
      },

      // Переход по ссылке
      followLink(link) {
        window.location = link;
      },

      // Редирект на десктоп с бесшовным переходом
      goDesktopWithAuth(link) {
        this.openDesktop({
          path: link.path,
          query: link.query,
          text: 'Открыть эту страницу в полной версии сайта?',
        });
      },

      // Создает объект из ссылки в уведомлении, содержащий путь и query-параметры
      findLink() {
        const url = this.parseUrl();
        if (!url) {
          return false;
        }

        return {
          path: url.pathname,
          query: this.parseQuery(url),
          href: url.href,
        };
      },

      // Проверяет нужен ли бесшовный переход на десктоп
      needAuth(pathname) {
        return pathname.includes('profile/sign_documents');
      },

      // Находит ссылку в теле уведомления и возвращает объект URL
      parseUrl() {
        // ищем ссылку в теле уведомления
        let normalLink = this.notification.body.match(/"(http(s)?:\/\/.*)"/gm);
        if (!normalLink) {
          return false;
        }

        // обрезаем кавычки
        normalLink = normalLink[0].substring(1, normalLink[0].length - 1);

        // возвращаем объект URL
        return new URL(normalLink);
      },

      // Парсит query-параметры и возвращает объект (ключ-значение)
      parseQuery(url) {
        return Object.fromEntries(new URLSearchParams(url.search));
      },
    },
  };
</script>

<style lang="scss">
  .notification-item {
    display: flex;
    align-items: flex-start;
    width: 100%;
    padding: 12px 0;
    border-bottom: 1px solid c('black10', 'light');

    &:last-child {
      border-bottom: none;
    }

    .notification-item__icon {
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      margin-left: 16px;
      padding: 8px 8.5px 7px 8.5px;
      border-radius: 3px;
      background-color: c('light', 'light');
      color: c('black80', 'light');
    }

    .notification-item__body {
      padding: 0 8px 0 16px;
      color: c('text', 'light');
    }

    .notification-item__title {
      @include font-size(14, 18, bold);
      padding-bottom: 4px;
      font-weight: 700;
    }

    .notification-item__content {
      @include font-size(14, 18, normal);
      color: c('text', 'light');

      a {
        color: c('primary', 'light');
        text-decoration: none;
        pointer-events: none;

        :hover {
          text-decoration: underline;
        }
      }
    }

    .notification-item__date {
      padding-top: 8px;
      color: #a0a4a8;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
    }

    .notification-item__button {
      padding-right: 12px;
      color: c('primary', 'light');
    }
  }

  .notification-item--read {
    color: c('black70', 'light');

    .notification-item__body {
      color: c('black60', 'light');
    }

    .notification-item__content {
      color: c('black60', 'light');

      a {
        color: c('text', 'light');
        pointer-events: none;
      }
    }

    .notification-item__button {
      color: c('black40', 'light');
    }
  }
</style>
