<template lang="pug">
v-footer.page-footer(app height=64)
  slot
</template>

<script>
  export default {};
</script>

<style lang="scss">
  .page-footer {
    background-color: c('white', 'light') !important;
    box-shadow: 0px -3px 8px rgba(0, 0, 0, 0.04) !important;
  }
</style>
